import { getUserLogin } from "@/composables/useAuthStore.js";

export const isPopSajuGroupChange = ref(false);
export const isPopSajuGroupSetting = ref(false);

export const isPopSajuGroupAdd = ref(false);

export const isPopMemoReg = ref(false);
export const isPopMemoList = ref(false);


export const groupListOpen = async () => {
  const userId = await getUserLogin(); 
  
  if (!userId) {
    alert('로그인 후 이용 가능 합니다.');
    return;
  }
  isPopSajuGroupChange.value = true;
}

export const openGroupChange = async () => {
  isPopSajuGroupChange.value = true;
}

export const closeGroupChange = async () => {
  isPopSajuGroupChange.value = false;
}

export const openGroupSetting = async () => {
  isPopSajuGroupSetting.value = true;
}
export const closeGroupSetting = async () => {
  isPopSajuGroupSetting.value = false;
}

export const openGroupAdd = async () => {
  isPopSajuGroupAdd.value = true;
}

export const openMemoReg = async () => {
  const userId = await getUserLogin(); 
  
  if (!userId) {
    alert('로그인 후 이용 가능 합니다.');
    return;
  }
  isPopMemoReg.value = true;
}
export const closeMemoReg = async () => {
  isPopMemoReg.value = false;
}

export const openMemoList = async () => {
  const userId = await getUserLogin(); 
  
  if (!userId) {
    alert('로그인 후 이용 가능 합니다.');
    return;
  }
  isPopMemoList.value = true;
}
export const closeMemoList = async () => {
  isPopMemoList.value = false;
}

