import _definePage_default_0 from '/var/www/d25_f_web_svcs/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/var/www/d25_f_web_svcs/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_3 from '/var/www/d25_f_web_svcs/src/pages/blank/index.vue?definePage&vue'
import _definePage_default_4 from '/var/www/d25_f_web_svcs/src/pages/calendar/index.vue?definePage&vue'
import _definePage_default_5 from '/var/www/d25_f_web_svcs/src/pages/main/index.vue?definePage&vue'
import _definePage_default_6 from '/var/www/d25_f_web_svcs/src/pages/manse/index.vue?definePage&vue'
import _definePage_default_7 from '/var/www/d25_f_web_svcs/src/pages/manse/print.vue?definePage&vue'
import _definePage_default_8 from '/var/www/d25_f_web_svcs/src/pages/mypage/index.vue?definePage&vue'
import _definePage_default_9 from '/var/www/d25_f_web_svcs/src/pages/mypage/faq.vue?definePage&vue'
import _definePage_default_10 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiry.vue?definePage&vue'
import _definePage_default_11 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiryGuest.vue?definePage&vue'
import _definePage_default_12 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiryList.vue?definePage&vue'
import _definePage_default_13 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiryView.vue?definePage&vue'
import _definePage_default_14 from '/var/www/d25_f_web_svcs/src/pages/mypage/myinfo.vue?definePage&vue'
import _definePage_default_15 from '/var/www/d25_f_web_svcs/src/pages/mypage/profile.vue?definePage&vue'
import _definePage_default_16 from '/var/www/d25_f_web_svcs/src/pages/saju/index.vue?definePage&vue'
import _definePage_default_17 from '/var/www/d25_f_web_svcs/src/pages/schedule_app/index.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/var/www/d25_f_web_svcs/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/auth',
    /* internal name: 'auth' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  {
    path: '/blank',
    /* internal name: 'blank' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'blank',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/blank/index.vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  {
    path: '/calendar',
    /* internal name: 'calendar' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'calendar',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/calendar/index.vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/customer',
    /* internal name: 'customer' */
    /* no component */
    children: [
      {
        path: '',
        name: 'customer',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/customer/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/essay',
    /* internal name: 'essay' */
    /* no component */
    children: [
      {
        path: '',
        name: 'essay',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/essay/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/main',
    /* internal name: 'main' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'main',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/main/index.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/manse',
    /* internal name: 'manse' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'manse',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/index.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
      {
        path: 'components',
        /* internal name: 'manse-components' */
        /* no component */
        children: [
          {
            path: 'Agree',
            name: 'manse-components-agree',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/Agree.vue'),
            /* no children */
          },
          {
            path: 'Alert',
            name: 'manse-components-alert',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/Alert.vue'),
            /* no children */
          },
          {
            path: 'ComLatest',
            name: 'manse-components-com-latest',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ComLatest.vue'),
            /* no children */
          },
          {
            path: 'ManseBirthMain',
            name: 'manse-components-manse-birth-main',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseBirthMain.vue'),
            /* no children */
          },
          {
            path: 'ManseCard',
            name: 'manse-components-manse-card',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCard.vue'),
            /* no children */
          },
          {
            path: 'ManseCardItem',
            name: 'manse-components-manse-card-item',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCardItem.vue'),
            /* no children */
          },
          {
            path: 'ManseDaeunMain',
            name: 'manse-components-manse-daeun-main',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseDaeunMain.vue'),
            /* no children */
          },
          {
            path: 'PopArea',
            name: 'manse-components-pop-area',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopArea.vue'),
            /* no children */
          },
          {
            path: 'PopGanji',
            name: 'manse-components-pop-ganji',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopGanji.vue'),
            /* no children */
          },
          {
            path: 'PopMansePrint',
            name: 'manse-components-pop-manse-print',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopMansePrint.vue'),
            /* no children */
          },
          {
            path: 'PopName',
            name: 'manse-components-pop-name',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopName.vue'),
            /* no children */
          },
          {
            path: 'PopShareBox',
            name: 'manse-components-pop-share-box',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopShareBox.vue'),
            /* no children */
          },
          {
            path: 'PrintItemComponent',
            name: 'manse-components-print-item-component',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PrintItemComponent.vue'),
            /* no children */
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'print',
        name: 'manse-print',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/print.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/mypage',
    /* internal name: 'mypage' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'mypage',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/index.vue'),
        /* no children */
      },
  _definePage_default_8
  ),
  _mergeRouteRecord(
      {
        path: 'faq',
        name: 'mypage-faq',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/faq.vue'),
        /* no children */
      },
  _definePage_default_9
  ),
  _mergeRouteRecord(
      {
        path: 'inquiry',
        name: 'mypage-inquiry',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiry.vue'),
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'inquiryGuest',
        name: 'mypage-inquiry-guest',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiryGuest.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'inquiryList',
        name: 'mypage-inquiry-list',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiryList.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
  _mergeRouteRecord(
      {
        path: 'inquiryView',
        name: 'mypage-inquiry-view',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiryView.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: 'myinfo',
        name: 'mypage-myinfo',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/myinfo.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
  _mergeRouteRecord(
      {
        path: 'profile',
        name: 'mypage-profile',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/profile.vue'),
        /* no children */
      },
  _definePage_default_15
  )
    ],
  },
  {
    path: '/saju',
    /* internal name: 'saju' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'saju',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/index.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
      {
        path: 'components',
        /* internal name: 'saju-components' */
        /* no component */
        children: [
          {
            path: 'PopAddGroup',
            name: 'saju-components-pop-add-group',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopAddGroup.vue'),
            /* no children */
          },
          {
            path: 'PopGroupDel',
            name: 'saju-components-pop-group-del',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopGroupDel.vue'),
            /* no children */
          },
          {
            path: 'PopMemoList',
            name: 'saju-components-pop-memo-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopMemoList.vue'),
            /* no children */
          },
          {
            path: 'PopMemoReg',
            name: 'saju-components-pop-memo-reg',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopMemoReg.vue'),
            /* no children */
          },
          {
            path: 'PopSajuGroupChange',
            name: 'saju-components-pop-saju-group-change',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopSajuGroupChange.vue'),
            /* no children */
          },
          {
            path: 'PopSajuGroupSetting',
            name: 'saju-components-pop-saju-group-setting',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopSajuGroupSetting.vue'),
            /* no children */
          },
          {
            path: 'sajuFilter',
            name: 'saju-components-saju-filter',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuFilter.vue'),
            /* no children */
          },
          {
            path: 'sajuFilterNew',
            name: 'saju-components-saju-filter-new',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuFilterNew.vue'),
            /* no children */
          },
          {
            path: 'sajuGrid',
            name: 'saju-components-saju-grid',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuGrid.vue'),
            /* no children */
          },
          {
            path: 'sajuList',
            name: 'saju-components-saju-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuList.vue'),
            /* no children */
          },
          {
            path: 'sajuOption',
            name: 'saju-components-saju-option',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuOption.vue'),
            /* no children */
          },
          {
            path: 'userGroup',
            name: 'saju-components-user-group',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/userGroup.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/schedule_app',
    /* internal name: 'schedule-app' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'schedule-app',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/schedule_app/index.vue'),
        /* no children */
      },
  _definePage_default_17
  )
    ],
  }
]
