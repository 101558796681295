<script setup>
  import { getGrouplist } from '@/api/UserGroup';

  const isDialogVisibleChange = ref(true);
  const emit = defineEmits();

  const close = () => {
    emit('close');
  };
  const listData = ref({});
  const getGroupList = async () => {
    try {
      const params = {};
      const data = await getGrouplist(params); // 그룹리스트 조회
      listData.value = data;
    } catch (error) {
      console.error('Failed to load users:', error);
    }
  }
  onMounted(async () => {
    await getGroupList();
  });
  const change = (selectItem) => {
    const selectedGroup = selectItem.userGroupId;
    const filteredData = listData.value.filter((item) => item.userGroupId === selectedGroup);
    emit('change', filteredData);
  }
  const openSetting = () => {
    emit('openSetting', 'type1');
  }
</script>
<template>
  <VDialog
    v-model="isDialogVisibleChange"
    class="v-dialog-sm dialog-st1 custom-dialog"
    max-width="620px"
    max-height="322px"
    hide-overlay
  >
    <VCard >
      <DialogCloseBtn
        variant="text"
        size="default"
        @click="close"
      />
      <div
        class="saju-pop-inner"
      >
        <div class="pop-tit-box">
          <!-- <div
            class="setting-button"
            @click="openSetting()"
          >
            <i class="icon-setting font-none xs">설정 아이콘</i><span>그룹관리</span>
          </div> -->
          <div class="">
            그룹 지정
          </div>
          <div
            class="close-button"
            @click="close()"
          >
            Close
          </div>
        </div>
        <div
          class="saju-pop-body"
        >
          <div
            class="pop-search-box"

          >
            <div class="pop-group-list scroll">
              <div
                v-for="(item, index) in listData"
                :key="index"
                class="group-item"
                :class="{ 'active': prSelectedGroup?.userGroup?.userGroupId === item.userGroupId}"
                @click="change(item)"
              >
                {{ item.name }} ({{ item.memberCount }})
              </div>
            </div>
          </div>
          <div class="notice-group">
            <span class="warn-text">*변경할 그룹을 선택해주세요. 선택된 그룹으로 바로 적용되므로 유의해주세요.</span>
          </div>
        </div>
      </div>
  </VCard>

  </VDialog>
</template>

<style lang="css" scoped>
/* .custom-dialog{
  display: flex;
  width: 600px;
  padding: 20px 0px 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
} */
</style>