<script setup>
import { onMounted } from 'vue'
const emit = defineEmits()

const props = defineProps({
  username: {
    type: String,
    default: null,
  },
  key: {
    type: String,
    default: null,
  },
})
const userName = ref(props.username)
const close = () => {
  emit('close')
}
const PopNameBox = ref(true)
const updateName = () => {
  emit('onChangeName', userName.value)
}
</script>
<template>
  <VDialog v-model="PopNameBox" class="v-dialog-sm" max-width="325px">
    <VCard>
      <div class="pop-inner" style="height: 246px">
        <div class="pop-tit-box">
          <div class="pop-tit-sub">정보 수정</div>
          <DialogCloseBtn variant="text" size="default" @click="close" />
        </div>
        <div class="pop-body pd-small">
          <div class="input-label">이름</div>
          <VTextField
            v-model="userName"
            class="h-mid"
            placeholder="이름"
            clearable
            maxlength="20"
          />
          <div class="p-exp-1">최소 1자에서 20자 까지 입력 가능</div>
        </div>
        <div class="btn-com bottom">
          <button class="btn-nor bottom left cancel" @click="close">취소</button>
          <button class="btn-nor bottom right submit" @click="updateName()">확인</button>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
